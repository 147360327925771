<template>
  <v-container fluid class="pl-12 pr-12">
    <ValidationForms ref="forms">
      <v-layout column wrap class="mx-auto">
        <v-card-title class="font-weight-black pl-3 mb-6" style="font-size:32px"
          >{{ $t('link.agencyInformationAggregate') }}
        </v-card-title>
        <v-row no-gutters class="ml-8">
          <p class="title my-auto">
            {{ $t('label.informationAggregateSalesCode') }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-6" no-gutters>
          <ValidationForm rules="salesCodeCheck">
            <v-flex md4>
              <v-text-field
                v-model="conditions.salesCode"
                :outlined="true"
                hide-details
              />
            </v-flex>
          </ValidationForm>
        </v-row>
        <v-row no-gutters class="ml-8">
          <p class="title my-auto">
            {{ $t('label.informationAggregateAgencyCode') }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-6" no-gutters>
          <ValidationForm rules="agencyCodeCheck">
            <v-flex md4>
              <v-text-field
                v-model="conditions.agencyCode"
                :outlined="true"
                hide-details
              />
            </v-flex>
          </ValidationForm>
        </v-row>

        <v-row no-gutters class="ml-8">
          <p class="title my-auto">{{ $t('label.targetMonthRange') }}</p>
          <v-chip
            class="ma-2 font-weight-black"
            color="next"
            dark
            label
            small
            >{{ $t('label.required') }}</v-chip
          >
        </v-row>
        <v-row class="ml-8 mb-6">
          <v-flex md4>
            <ValidationForm rules="required">
              <VueMonthlyPicker
                :max="maxDate"
                dateFormat="YYYY-MM"
                selectedBackgroundColor="#007bff"
                :value="conditions.month"
                :clearOption="false"
                @selected="monthSelect"
              ></VueMonthlyPicker>
            </ValidationForm>
          </v-flex>
        </v-row>
        <v-row>
          <v-btn
            class="font-weight-black ml-8"
            align="center"
            width="30vw"
            max-width="170px"
            color="next"
            style="font-size:20px"
            :dark="!isCsvExport"
            :disabled="isCsvExport"
            @click="csvDownload"
            >{{ $t('button.csvDownload') }}</v-btn
          >
        </v-row>
        <ErrorDialog ref="errorDialog"></ErrorDialog>
      </v-layout>
    </ValidationForms>
    <section></section>
  </v-container>
</template>

<script>
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import VueMonthlyPicker from 'vue-monthly-picker';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { getAgencyInformationAggregate } from '@/apis/agency/agencies';
import moment from 'moment';
import { AgencyInformationAggregateCsv } from '@/lib/const';
import { downloadCsv, getCsvFileName } from '@/lib/csv';

export default {
  name: 'AgencyInformationAggregate',
  components: {
    ValidationForms,
    ValidationForm,
    VueMonthlyPicker,
    ErrorDialog,
  },
  data() {
    return {
      conditions: {
        month: moment()
          .subtract(1, 'months')
          .format('YYYY-MM'),
        agencyCode: null,
        salesCode: null,
      },
      maxDate: moment()
        .subtract(1, 'months')
        .format('YYYY-MM'),
      agencyList: null,
      isCsvExport: false,
    };
  },

  methods: {
    monthSelect(value) {
      // フォーマットを表示内容と統一
      this.$set(this.conditions, 'month', moment(value).format('YYYY-MM'));
    },

    async csvDownload() {
      // 連打を防止するためCSV出力ボタンを無効化
      this.$set(this, 'isCsvExport', true);
      if (await this.$refs.forms.validate()) {
        this.$set(
          this,
          'agencyList',
          await getAgencyInformationAggregate(this.conditions)
        );
        // 対象が0件の場合エラーメッセージを表示してCSV出力を行わない
        if (this.agencyList.rows.length <= 0) {
          this.$set(this, 'isCsvExport', false);
          this.$refs.errorDialog.open(
            this.$t('title.agency.csvExportError'),
            this.$t('error.notFoundAgencyList')
          );
          return;
        }
        // 出力データ数が最大行数を上回った場合
        if (this.agencyList.count > AgencyInformationAggregateCsv.maxRowCount) {
          this.$refs.errorDialog.open(
            this.$t('title.agency.csvExportError'),
            this.$t('error.invalidCsvMaxRowCount', {
              maxRowCount: AgencyInformationAggregateCsv.maxRowCount,
            })
          );
          return;
        }

        const headers = {};

        AgencyInformationAggregateCsv.headers.forEach((header, index) => {
          // ヘッダー名をセットする
          headers[AgencyInformationAggregateCsv.columns[index]] = header;
        });

        const rows = this.agencyList.rows.map(agency => ({
          salesCode: agency.sale ? agency.sale.salesCode : '',
          salesDepartmentName: agency.sale
            ? agency.sale.salesDepartmentName
            : '',
          salesSectionName: agency.sale ? agency.sale.salesSectionName : '',
          agencyCode: agency.agencyCode,
          agencyName: agency.agencyName,
          created: moment(agency.createdAt).format('YYYY/MM/DD HH:mm'),
        }));

        await downloadCsv(
          rows,
          headers,
          getCsvFileName(
            this.$t('link.agencyInformationAggregate') +
              '_' +
              this.conditions.month
          )
        );
      }
      this.$set(this, 'isCsvExport', false);
    },
  },
};
</script>
